import React from "react";
import { graphql } from "gatsby";

// Component imports
import PainLoggerLayout from "../../components/pain_logger/PainLoggerLayout";
import PDFViewer from "../../components/common/PDFViewer";
import SEO from "../../components/common/seo";

// CSS imports
import "../../assets/sass/app_page.scss";

export default class PainLoggerUserGuide extends React.Component {

  render() {
    const { publicURL: fileURL, name: fileName } = this.props.data.allFile.edges[0].node
    return (
      <PainLoggerLayout>
        <SEO title="Pain Logger User Guide" keywords={[`pain`, `painlogger`, `user guide`]} />
        <PDFViewer 
          title="Pain Logger User Guide"
          fileName={fileName}
          fileURL={fileURL} />
      </PainLoggerLayout>
    );
  }
}

export const query = graphql`
{
	allFile(filter: {extension: {eq: "pdf"}, name: {eq: "Pain_Logger_User_Guide_Outlined"}}) {
    edges {
      node {
        publicURL
        name
      }
    }
  }
}
`